import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const HamburgerMenu = ({ menuOptions, setShowMenu, showMenu }) => (
  <div className='relative'>
    <div
      onClick={(e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
      }}
    >
      <Image
        {...{
          src: `${staticMediaStoreBaseURL}/icons/${
            showMenu ? 'active-hamburger-icon' : 'hamburger-icon'
          }.svg`,
          height: 32,
          width: 36,
          alt: 'hamburger cta',
          className: 'cursor-pointer'
        }}
      />
    </div>
    {showMenu && (
      <div className='absolute top-10 z-10 -left-40 w-48 bg-white rounded py-0 flex flex-col text-sm text-dim-gray font-medium shadow-card'>
        {menuOptions.map(
          (
            { alt, label, icon, hoverIcon, onClick, isInactive = false },
            index
          ) => (
            <div
              key={index}
              className={`flex gap-1 px-4 py-2 hover:bg-neutral hover:text-nero hamburger-menu-item ${
                isInactive ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!isInactive) {
                  onClick();
                  setShowMenu(false);
                }
              }}
            >
              <div className='group-menu-icon'>
                <Image
                  {...{
                    src: `${staticMediaStoreBaseURL}/icons/${icon}`,
                    width: 20,
                    height: 20,
                    alt
                  }}
                />
              </div>
              <div className='group-menu-icon-hover hidden'>
                <Image
                  {...{
                    src: `${staticMediaStoreBaseURL}/icons/${hoverIcon}`,
                    width: 20,
                    height: 20,
                    alt
                  }}
                />
              </div>
              <p className={`${label === 'Book Order' ? 'leading-6' : ''}`}>
                {label}
              </p>
            </div>
          )
        )}
      </div>
    )}
  </div>
);

export default HamburgerMenu;
