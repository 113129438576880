import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const UnSavedChangesErrorMessage = ({ showUnSavedChangesErrorMessage }) =>
  showUnSavedChangesErrorMessage && (
    <div className='flex gap-2 -mt-1'>
      <Image
        {...{
          src: `${staticMediaStoreBaseURL}/icons/failed.svg`,
          width: 16,
          height: 16,
          alt: 'error',
          className: 'self-center'
        }}
      />
      <div className='text-xs font-medium text-error-base self-center'>
        There are unsaved changes on this block, please Save or Cancel the
        changes first to proceed.
      </div>
    </div>
  );

export default UnSavedChangesErrorMessage;
