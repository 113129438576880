import Image from 'next/image';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/helpers/formatNumber';

const PromoCodeSuccessModal = ({
  promoCodeDetails,
  promoCodeDiscountAmount,
  setShowPromoCodeAppliedModal,
  showPromoCodeAppliedModal
}) => (
  <Modal
    fade={false}
    isOpen={showPromoCodeAppliedModal}
    toggle={setShowPromoCodeAppliedModal}
  >
    <ModalBody className='mx-4'>
      <div className='z-50 bg-white w-full mt-20 md:w-2/5 md:min-h-107.5 min-h-80 relative rounded-2xl h-full flex items-center md:mx-auto'>
        <div className='z-50 text-black absolute right-0 top-0 md:flex flex-row-reverse p-3 md:p-4 '>
          <Image
            alt='close icon'
            className='cursor-pointer'
            height={20}
            onClick={() => setShowPromoCodeAppliedModal(false)}
            src={`${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`}
            width={20}
          />
        </div>
        <div className='flex items-center text-center flex-col w-52 mx-auto my-12 md:my-19.5'>
          <div className='w-16 h-15 md:w-22.5 md:h-21 relative cursor-pointer mx-1 flex-grow-0 rounded-md overflow-hidden mb-6'>
            <Image
              alt='fire cracker icon'
              layout='fill'
              objectFit='cover'
              src={`${staticMediaStoreBaseURL}/promoCode/fireworksPromoCode.svg`}
            />
          </div>
          <h4 className='font-bold text-xl text-nero'>
            {promoCodeDetails?.code ?? ''}
          </h4>
          <h4 className='font-bold text-xl text-nero'>applied successfully!</h4>
          <span className='text-nero text-lg font-above-normal leading-6 mt-4'>
            You saved AED{' '}
            {parseFormatPriceValueFromAPI(promoCodeDiscountAmount)} with this
            promo code
          </span>
          <div className=' h-5 w-28 relative cursor-pointer mx-1 flex-grow-0 rounded-md overflow-hidden mt-6'>
            <Image
              alt='cart Item Add On'
              layout='fill'
              objectFit='cover'
              src={`${staticMediaStoreBaseURL}/promoCode/Woohoo!.svg`}
            />
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default PromoCodeSuccessModal;

PromoCodeSuccessModal.propTypes = {
  promoCodeDetails: PropTypes.object,
  promoCodeDiscountAmount: PropTypes.number,
  setShowPromoCodeAppliedModal: PropTypes.func,
  showPromoCodeAppliedModal: PropTypes.bool
};
