import { parseFormatPriceValueFromAPI } from '@/helpers/formatNumber';

const Price = ({ value, isDiscount }) => (
  <span
    className={`font-above-normal text-sm ${
      isDiscount ? 'text-green' : 'text-dim-gray'
    }`}
  >
    {isDiscount && '- '} {parseFormatPriceValueFromAPI(value)}
  </span>
);

export default Price;
