import { OPS_PORTAL_URL } from '@/services/connections.service';

export const getEditPartnerURL = ({ partnerId }) =>
  `${OPS_PORTAL_URL}/partners/${partnerId}/edit/basic-details`;

export const getEditProductURL = ({ productId }) =>
  `${OPS_PORTAL_URL}/products/${productId}/edit/basic-details`;

export const getViewProductOrderHistoryURL = ({ productId }) =>
  `${OPS_PORTAL_URL}/products/${productId}/edit/product-order-history`;

export const getEditOrderURL = ({ orderId }) =>
  `${OPS_PORTAL_URL}/orders/${orderId}/details/order-cart-details`;
