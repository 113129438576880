import Uppy from '@uppy/core';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { DashboardModal } from '@uppy/react';
import ImageKitUppyPlugin from 'imagekit-uppy-plugin';
import Image from 'next/image';
import { Modal, ModalBody } from 'reactstrap';

import { staticMediaStoreBaseURL } from '@/config/common';
import {
  getImageKitAuth,
  reconcileCartItemMediaWithImageKit
} from '@/services/plannerCart.service';

const environmentName = process.env.NEXT_PUBLIC_APP_ENV;
const imageKitPublicKey = process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY;

// TODO: Hardik, pl. add toast message in case API response status is not true, or if code lands in catch block.
const imageKitAuthenticator = async () => {
  try {
    const { entity } = await getImageKitAuth();
    return entity;
  } catch (error) {
    throw new Error(`Authentication request failed: ${error.message}`);
  }
};

const metaFields = [
  {
    id: 'customCoordinates',
    name: 'Custom coordinates',
    placeholder: 'Comma seperated values in format x,y,width,height'
  },
  {
    id: 'isPrivateFile',
    name: 'Private File',
    render({ value, onChange }, h) {
      return h('input', {
        type: 'checkbox',
        onChange: (ev) => onChange(ev.target.checked ? 'true' : 'false'),
        checked: value === 'true' || value === true,
        style: {
          verticalAlign: 'middle'
        }
      });
    }
  },
  {
    id: 'name',
    name: 'File name',
    placeholder: 'Enter the file name'
  },
  {
    id: 'tags',
    name: 'Tags',
    placeholder: 'Comma seperated tags e.g. t-shirt, summer'
  },
  {
    id: 'useUniqueFileName',
    name: 'Use unique file name',
    render({ value, onChange }, h) {
      return h('input', {
        type: 'checkbox',
        onChange: (ev) => onChange(ev.target.checked ? 'true' : 'false'),
        checked: value === '' || value === 'true' || value === true,
        style: {
          verticalAlign: 'middle'
        }
      });
    }
  }
];

const getCartItemMediaDirectoryPath = ({ cartId, cartItemId }) =>
  `API/${environmentName}/db/carts/${cartId}/${cartItemId}/`;

const contentSink = `/API/${environmentName}/dump`;

const getUppyInstance = ({ directoryPath }) => {
  const uppy = new Uppy({
    id: 'Uppy',
    allowMultipleUploads: true,
    autoProceed: false,
    debug: false,
    restrictions: {
      maxFileSize: 15 * 1048576,
      maxNumberOfFiles: 10,
      allowedFileTypes: ['image/*']
    },
    onBeforeUpload: (files) => {
      const updatedFiles = {};
      Object.keys(files).forEach((fileKey) => {
        updatedFiles[fileKey] = {
          ...files[fileKey],
          meta: {
            ...files[fileKey].meta,
            folder: directoryPath || contentSink,
            useUniqueFileName: true
          }
        };
      });
      return updatedFiles;
    }
  }).use(ImageKitUppyPlugin, {
    id: 'ImageKitUppyPlugin',
    authenticator: imageKitAuthenticator,
    publicKey: imageKitPublicKey,
    metaFields: [
      'folder',
      'customCoordinates',
      'isPrivateFile',
      'name',
      'responseFields',
      'tags',
      'useUniqueFileName'
    ]
  });
  return uppy;
};

const callReconcileCartItemMediaWithImageKitApi = async ({
  cartId,
  cartItemId
}) => reconcileCartItemMediaWithImageKit({ cartId, cartItemId });

const UploadCartItemMediaModal = ({
  cartId,
  cartItemId,
  setCartItemIdForUploadMediaModal
}) => {
  const directoryPath = getCartItemMediaDirectoryPath({ cartId, cartItemId });
  const uppy = getUppyInstance({ directoryPath });
  uppy.on('upload-success', (success) => success);
  uppy.on('complete', (result) => result);
  uppy.on('error show toast message', (error) => error);
  uppy.on('upload-error show toast message', (error) => error);

  const uninstallPluginCloseModal = () => {
    uppy.close();
    setCartItemIdForUploadMediaModal(null);
  };

  const doneButtonHandler = async () => {
    // TODO:  Rajesh/Hardik: ”media is uploaded, wait a few minutes to see in image carousal” block screen/loader
    await callReconcileCartItemMediaWithImageKitApi({
      cartId,
      cartItemId
    });
    uninstallPluginCloseModal();
  };

  return (
    <Modal
      isOpen={true}
      toggle={uninstallPluginCloseModal}
      fade={true}
    >
      <ModalBody>
        <div className='flex h-screen justify-center'>
          <div className='self-center relative'>
            <span
              className='flex justify-end cursor-pointer relative top-7 -left-2 z-50'
              onClick={uninstallPluginCloseModal}
            >
              <Image
                src={`${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`}
                width={20}
                height={20}
                alt='cross'
                layout='fixed'
              />
            </span>
            <DashboardModal
              uppy={uppy}
              id='DashboardModal'
              name='DashboardModalName'
              animateOpenClose={true}
              browserBackButtonClose={true}
              centerSingleFile={true}
              closeAfterFinish={false}
              closeModalOnClickOutside={true}
              disablePageScrollWhenModalOpen={true}
              disableStatusBar={false}
              doneButtonHandler={doneButtonHandler}
              fileManagerSelectionType='files'
              hideAfterFinish={false}
              hideProgressAfterFinish={false}
              inline={true}
              metaFields={metaFields}
              onRequestCloseModal={uninstallPluginCloseModal}
              open={true}
              plugins={['dashboard']}
              proudlyDisplayPoweredByUppy={false}
              showLinkToFileUploadResult={true}
              showProgressDetails={true}
              showRemoveButtonAfterComplete={true}
              theme='auto'
              // trigger='#uppyDashboard'
              // target='.uppy-dashboard-container'
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadCartItemMediaModal;
