// #TODO: this file should be in host cart folder rather than common folder.

import Image from 'next/image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import validator from 'validator';

import { staticMediaStoreBaseURL } from '@/config/common';
import debounce from '@/helpers/debounce';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import {
  PAGE_NAME,
  captureGTMEventAddToCart,
  captureGTMEventRemoveFromCart,
  updateGTMEventCartQuantity
} from '@/lib/gtm';

const minMaxRestriction = ({ min, max }) => ({
  max: max || Infinity,
  min: min || 1
});

const SpinnerWithInput = ({
  availableDiscount,
  categories,
  disabled,
  id,
  imageSizeMobile = 28,
  imageSizeWeb = 40,
  inputClassName = 'text-sm md:text-base font-medium',
  minPrice,
  pageName,
  price,
  productName,
  productType,
  quantity: initialValue,
  range: { min = 1, max = Infinity },
  spinnerContainerClass = 'gap-0 w-24 md:w-30 h-7',
  step = 1,
  updateCartItemDetail
}) => {
  const [isMobile] = useIsMobile();
  const [value, setValue] = useState(initialValue);
  const canIncrease = value + step <= max;
  const canDecrease = value - step >= min;
  const canChange = !disabled;

  const updateValue = (newValue) => {
    const adjustedValue = newValue * step;
    setValue(adjustedValue);
    debounce(updateCartItemDetail({ quantity: adjustedValue, id }));
  };

  return (
    <div className={`flex ${spinnerContainerClass}`}>
      <div>
        <Image
          alt='reduce-quantity'
          className={`${!disabled && 'cursor-pointer'}`}
          height={isMobile ? imageSizeMobile : imageSizeWeb}
          onClick={() => {
            captureGTMEventRemoveFromCart({
              availableDiscount,
              categories,
              id,
              minPrice,
              name: productName,
              pageName: PAGE_NAME.HOST_CART.label,
              price,
              quantity: value,
              type: productType
            });
            return canChange && canDecrease && updateValue(value - step);
          }}
          src={`${staticMediaStoreBaseURL}/icons/minus-qty-icon.svg`}
          width={isMobile ? imageSizeMobile : imageSizeWeb}
        />
      </div>
      <input
        {...{
          className: `${inputClassName} border-t border-b w-6 md:w-16 flex-1 outline-none placeholder:text-grey-400 text-center `,
          value,
          onChange: (e) => {
            const quantityValue = e.target.value;
            e.preventDefault();
            updateGTMEventCartQuantity({
              availableDiscount,
              currentQuantity: quantityValue,
              id,
              minPrice,
              name: productName,
              oldQuantity: value,
              pageName,
              price,
              type: productType
            });

            validator.isInt(quantityValue, minMaxRestriction({ min, max })) &&
              updateValue(quantityValue);
          },
          disabled
        }}
      />
      <div>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/${
            disabled ? 'disable-plus-qty-icon.svg' : 'plus-qty-icon.svg'
          }`}
          alt='increase-quantity'
          className={`${!disabled && 'cursor-pointer'}`}
          height={isMobile ? imageSizeMobile : imageSizeWeb}
          onClick={() => {
            captureGTMEventAddToCart({
              availableDiscount,
              categories,
              minPrice,
              name: productName,
              pageName: PAGE_NAME.HOST_CART.label,
              price,
              quantity: value,
              type: productType
            });
            return canChange && canIncrease && updateValue(value + step);
          }}
          width={isMobile ? imageSizeMobile : imageSizeWeb}
        />
      </div>
    </div>
  );
};

SpinnerWithInput.propTypes = {
  disabled: PropTypes.bool,
  onQuantityUpdate: PropTypes.func,
  quantity: PropTypes.number
};

export default SpinnerWithInput;
