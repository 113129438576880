import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';
import { getViewProductOrderHistoryURL } from '@/services/opsPortal.service';

const CartItemCommonCTA = ({
  onClick,
  className = '',
  imgIcon = '',
  alt = ''
}) => (
  <div
    className={`flex w-6 h-6 items-center bg-neutral
    rounded-full justify-center ${className}`}
  >
    <Image
      {...{
        width: 26,
        height: 26,
        src: `${staticMediaStoreBaseURL}${imgIcon}`,
        alt,
        onClick,
        className: 'cursor-pointer'
      }}
    />
  </div>
);

const ViewCartItemInOpsPortalCTA = ({ className, additionalProps }) => {
  const { productId } = additionalProps;
  return (
    <div className={className}>
      <Link
        href={`${getViewProductOrderHistoryURL({ productId })}`}
        rel='noreferrer'
      >
        <a target='_blank'>
          <div className='clickable'>
            <Image
              width={24}
              height={24}
              src={`${staticMediaStoreBaseURL}/icons/hafla-round-icon.svg`}
              alt='view item detail'
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

const CTAProps = {
  delete: {
    imgIcon: '/icons/delete-icon-with-gray-frame.svg',
    alt: 'delete item'
  },
  cloneCartItem: {
    imgIcon: '/icons/clone-rounded-icon.svg',
    alt: 'clone cart item'
  },
  uploadImage: { imgIcon: '/icons/upload-img-icon.svg', alt: 'upload image' },
  viewEditDetail: {
    imgIcon: '/icons/view-red-with-gray-frame.svg',
    alt: 'edit item'
  }
};

const defaultOnClick = () => {};

const CartItemCTA = ({
  additionalProps = {},
  className,
  onClick = defaultOnClick,
  type
}) => {
  const CTAPropsByType = CTAProps[type];
  const CTA =
    type === 'viewInOps' ? ViewCartItemInOpsPortalCTA : CartItemCommonCTA;
  return (
    <CTA {...{ className, onClick, additionalProps, ...CTAPropsByType }} />
  );
};

export default CartItemCTA;
