import Image from 'next/image';
import DatePickerContainer from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { staticMediaStoreBaseURL } from '@/config/common';

const DatePicker = ({
  dbName,
  setValue,
  selectedDate,
  setSelectedDate,
  errors,
  minDate,
  className,
  baseClass = 'w-full border border-gray-300 rounded-lg text-sm datepicker-input'
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  const calculatedMinDate = minDate ? new Date(minDate) : null;
  const availableSelectedDate = selectedDate ? new Date(selectedDate) : null;

  return (
    <div className='relative'>
      <DatePickerContainer
        showIcon
        minDate={calculatedMinDate}
        selected={availableSelectedDate}
        onChange={(e) => {
          setValue && setValue(dbName, new Date(e));
          setSelectedDate(e);
        }}
        openToDate={availableSelectedDate || calculatedMinDate}
        placeholderText='MM/DD/YYYY'
        calendarClassName=''
        className={`${className} ${baseClass}`}
      />
      <span className='absolute left-2 top-2'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/calendar-gray-with-frame.svg`}
          width={18}
          height={18}
          alt='datepicker'
          layout='fixed'
        />
      </span>
      {isInvalidMessage && (
        <div className='text-xs text-red-500'>{isInvalidMessage}</div>
      )}
    </div>
  );
};

export default DatePicker;
