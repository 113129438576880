import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import copyToClipBoard from '@/helpers/copyToClipBoard';

const UuidValue = ({ value, valueClass }) => (
  <div className={valueClass}>{value.substring(0, 8)}</div>
);

const HyperLinkedUuid = ({ link, value, valueClass }) => (
  <Link href={link}>
    <a className='self-center'>
      <UuidValue {...{ value, valueClass }} />
    </a>
  </Link>
);

const Uuid = ({
  labelClass,
  label = 'ID: ',
  value = '',
  valueClass = 'text-base',
  link = ''
}) => (
  <Fragment>
    <div className={labelClass}>{label}</div>
    <div className='flex gap-3'>
      {link ? (
        <HyperLinkedUuid {...{ link, value, valueClass }} />
      ) : (
        <UuidValue {...{ value, valueClass }} />
      )}
      <Image
        src={`${staticMediaStoreBaseURL}/icons/copy.svg`}
        width={20}
        height={20}
        alt='copy icon'
        onClick={() => copyToClipBoard(value)}
        className='cursor-pointer'
      />
    </div>
  </Fragment>
);

export default Uuid;
