import Image from 'next/image';
import { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';
import { Modal, ModalBody } from 'reactstrap';

import { CART_ORDER_SOURCE, staticMediaStoreBaseURL } from '@/config/common';
import {
  GRID_BOXES_PER_ROW,
  GRID_BOX_HEIGHT,
  downloadImage,
  getDropZoneHeight,
  getImageURL
} from '@/helpers/carousel';

const CloseIcon = ({ setOpenImageGalleryModal }) => (
  <span className='flex justify-end cursor-pointer'>
    <Image
      onClick={() => setOpenImageGalleryModal(false)}
      src={`${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`}
      width={20}
      height={20}
      alt='cross'
      layout='fixed'
    />
  </span>
);

const SaveCancelCTA = ({
  additionalLineItemMedia,
  cartItemId,
  cartItemMedia,
  setCartItemMedia,
  setPendingReorderRequest,
  updateCartItemMediaSortOrder
}) => (
  <div className='flex gap-2'>
    <button
      {...{
        className:
          'bg-gray px-3 py-2 justify-center md:text-base text-gray-700 rounded-lg text-sm font-medium',
        type: 'button',
        onClick: () => {
          setPendingReorderRequest(false);
          setCartItemMedia(additionalLineItemMedia);
        }
      }}
    >
      Cancel
    </button>
    <button
      {...{
        className:
          'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-3 py-2 rounded-lg text-sm font-medium',
        type: 'button',
        onClick: () => {
          const changedCartItemMediaSortOrder = cartItemMedia.map(
            ({ cartItemMediaId }, index) => ({
              id: cartItemMediaId,
              sortOrder: index + 1
            })
          );
          updateCartItemMediaSortOrder({
            changedCartItemMediaSortOrder,
            cartItemId
          });
          setPendingReorderRequest(false);
        }
      }}
    >
      Save
    </button>
  </div>
);

const CartItemMediaHeader = ({
  additionalLineItemMedia,
  cartItemId,
  cartItemMedia,
  isCartEditable,
  isReadOnly,
  pendingReorderRequest,
  setCartItemIdForUploadMediaModal,
  setCartItemMedia,
  setOpenImageGalleryModal,
  setPendingReorderRequest,
  unAvailableCartItem,
  updateCartItemMediaSortOrder
}) => {
  const showUploadMediaCTA =
    isCartEditable &&
    !unAvailableCartItem &&
    !pendingReorderRequest &&
    !isReadOnly;
  return (
    <div className='flex justify-between mb-4'>
      <div className='text-base font-medium text-dim-gray'>
        Additional Media
      </div>
      {showUploadMediaCTA && (
        <UploadMedia
          {...{
            cartItemId,
            setCartItemIdForUploadMediaModal,
            setOpenImageGalleryModal
          }}
        />
      )}
      {pendingReorderRequest && (
        <SaveCancelCTA
          {...{
            additionalLineItemMedia,
            cartItemId,
            cartItemMedia,
            setCartItemMedia,
            setPendingReorderRequest,
            updateCartItemMediaSortOrder
          }}
        />
      )}
    </div>
  );
};

const MediaGridItem = ({
  cartItemId,
  media,
  onDeleteCartItemImage,
  setCarouselModalImage,
  showDeleteIcon = false
}) => (
  <div className='relative planner-cart-gallery-box rounded-xl cursor-pointer border border-platinum'>
    <Image
      src={getImageURL(media)}
      layout='fill'
      objectFit='contain'
      alt='planner-cart-gallery'
      className='rounded-xl'
    />
    <div className='absolute black-overlayer w-full h-full rounded-xl z-10 flex items-center justify-center gap-3'>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/download-white.svg`}
        width={20}
        height={20}
        alt='view icon'
        className='cursor-pointer'
        onClick={() =>
          downloadImage({
            imageUrl: getImageURL(media),
            filename: media.filename || media.url.replaceAll('/', '_')
          })
        }
      />
      <Image
        src={`${staticMediaStoreBaseURL}/icons/view-icon.svg`}
        width={20}
        height={20}
        alt='view icon'
        className='cursor-pointer'
        onClick={() => setCarouselModalImage(media)}
      />
      {showDeleteIcon && (
        <Image
          src={`${staticMediaStoreBaseURL}/icons/delete-white.svg`}
          width={20}
          height={20}
          alt='delete icon'
          className='cursor-pointer'
          onClick={() =>
            onDeleteCartItemImage({
              cartItemId,
              cartItemMediaId: media.cartItemMediaId
            })
          }
        />
      )}
    </div>
  </div>
);

// TODO: Viresh, Hardik - find an alternate to style, so that class can be used in GridDropZone
const CartItemMediaGrid = ({
  cartItemId,
  mediaList = [],
  onDragImageTile,
  onDeleteCartItemImage,
  setCarouselModalImage,
  showDeleteIcon = false
}) => (
  <GridContextProvider onChange={onDragImageTile}>
    <GridDropZone
      id='MediaGrid-images'
      boxesPerRow={GRID_BOXES_PER_ROW}
      rowHeight={GRID_BOX_HEIGHT}
      style={{ height: getDropZoneHeight({ mediaList }) }}
    >
      {mediaList.map((media, index) => (
        <GridItem key={index}>
          <MediaGridItem
            {...{
              cartItemId,
              media,
              onDeleteCartItemImage,
              setCarouselModalImage,
              showDeleteIcon
            }}
          />
        </GridItem>
      ))}
    </GridDropZone>
  </GridContextProvider>
);

const MediaGrid = ({ mediaList = [], setCarouselModalImage }) =>
  mediaList.map((media, index) => (
    <MediaGridItem
      key={index}
      {...{
        media,
        setCarouselModalImage
      }}
    />
  ));

const UploadMedia = ({
  cartItemId,
  setCartItemIdForUploadMediaModal,
  setOpenImageGalleryModal
}) => (
  <a
    className='text-sm font-medium text-brand flex border border-brand rounded-md px-4 py-2 cursor-pointer'
    onClick={() => {
      setCartItemIdForUploadMediaModal(cartItemId);
      setOpenImageGalleryModal(false);
    }}
  >
    <Image
      src={`${staticMediaStoreBaseURL}/icons/upload.svg`}
      width={18}
      height={18}
      alt='upload image'
      className='px-1'
    />
    Upload Media
  </a>
);

const ImageGalleryModal = ({
  additionalLineItemMedia,
  cartItemId,
  isCartEditable,
  isReadOnly,
  isShowProductMedia,
  itemMediaForCarousel,
  onClickToggleButton,
  onDeleteCartItemImage,
  ProductMediaToggleForPlanner,
  productName,
  productSource,
  setCarouselModalImage,
  setCartItemIdForUploadMediaModal,
  setOpenImageGalleryModal,
  unAvailableCartItem,
  updateCartItemMediaSortOrder
}) => {
  const productMedia = itemMediaForCarousel.filter(
    ({ entityType }) => entityType === 'product'
  );

  const [pendingReorderRequest, setPendingReorderRequest] = useState(false);
  const [cartItemMedia, setCartItemMedia] = useState(additionalLineItemMedia);

  const keyPressEventAction = ({ key: keyPressed }) =>
    keyPressed === 'Escape' && setOpenImageGalleryModal(false);

  useEffect(() => {
    document.addEventListener('keyup', keyPressEventAction);
    return () => document.removeEventListener('keyup', keyPressEventAction);
  }, []);

  const onDragImageTile = (sourceId, sourceIndex, targetIndex) => {
    setPendingReorderRequest(true);
    const sortedCartItemMediaList = swap(
      cartItemMedia,
      sourceIndex,
      targetIndex
    );
    setCartItemMedia(sortedCartItemMediaList);
  };

  const additionalLineItemMediaPresent = additionalLineItemMedia?.length > 0;
  const showProductMediaToggle =
    productSource === CART_ORDER_SOURCE.CART &&
    (additionalLineItemMediaPresent || !isShowProductMedia);

  return (
    <Modal
      centered
      size='lg'
      isOpen={true}
      backdrop={true}
      fade={false}
    >
      <ModalBody>
        <div className='m-6 bg-white rounded-xl px-6 pb-6'>
          <div className='flex justify-between pt-7 mb-4'>
            <div className='text-xl font-medium text-nero'>
              <div className='text-xl font-medium text-dim-gray pb-3'>
                {productName}
              </div>
              {showProductMediaToggle && (
                <ProductMediaToggleForPlanner
                  {...{
                    isShowProductMedia,
                    onClickToggleButton
                  }}
                />
              )}
            </div>
            <CloseIcon {...{ setOpenImageGalleryModal }} />
          </div>
          <div className='h-80vh'>
            <CartItemMediaHeader
              {...{
                additionalLineItemMedia,
                cartItemId,
                cartItemMedia,
                isCartEditable,
                isReadOnly,
                pendingReorderRequest,
                setCartItemIdForUploadMediaModal,
                setCartItemMedia,
                setOpenImageGalleryModal,
                setPendingReorderRequest,
                unAvailableCartItem,
                updateCartItemMediaSortOrder
              }}
            />
            {!unAvailableCartItem ? (
              <CartItemMediaGrid
                {...{
                  cartItemId,
                  mediaList: cartItemMedia,
                  onDeleteCartItemImage,
                  onDragImageTile,
                  setCarouselModalImage,
                  showDeleteIcon: !isReadOnly
                }}
              />
            ) : (
              <div className='flex flex-wrap gap-4'>
                <MediaGrid
                  {...{
                    mediaList: cartItemMedia,
                    setCarouselModalImage
                  }}
                />
              </div>
            )}
            <div className='text-base font-medium text-dim-gray mt-6 mb-4'>
              Existing Media
            </div>
            <div className='flex flex-wrap gap-4'>
              {productMedia && (
                <MediaGrid
                  {...{
                    mediaList: productMedia,
                    setCarouselModalImage
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageGalleryModal;
